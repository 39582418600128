<template>
  <div class="privacy-policy-wrapper">
    <div class="container">
      <div class="head-text">
        ООО «Лаборатория эмоционального интеллекта» <br />
        (ООО «Лаборатория ЭИ») <br />
        ИНН 7703438337
      </div>

      <div class="notation">Редакция от 21.08.2023 г.</div>

      <h1>Политика в отношении <br />обработки персональных данных</h1>

      <h2>Термины и определения</h2>
      <div class="terms">
        <div>Администратор</div>
        <div>
          Общество с ограниченной ответственностью «Лаборатория эмоционального
          интеллекта», которое является оператором Персональных данных
          Пользователей
        </div>
        <div>Политика</div>
        <div>
          локальный документ Администратора, определяющий его политику в
          отношении обработки и защиты персональных данных Пользователей
        </div>
        <div>Система</div>
        <div>
          сервис Администратора, расположенный по адресу:
          <a href="https://way2wei.ru">https://way2wei.ru</a>
          (включая поддомены)
        </div>
        <div>Пользователь</div>
        <div>
          физическое лицо, использующее Систему, субъект персональных данных
        </div>
        <div>Персональные данные</div>
        <div>
          любая информация, относящаяся прямо или косвенно к определенному или
          определяемому Пользователю
        </div>
        <div>Обработка Персональных данных (Обработка)</div>
        <div>
          любое действие (операция) или совокупность действий (операций) с
          Персональными данными, совершаемых с использованием средств
          автоматизации или без их использования, в том числе: сбор, запись,
          систематизация, накопление, хранение, уточнение (обновление,
          изменение), извлечение, использование, передача (распространение,
          предоставление, доступ), обезличивание, блокирование, удаление,
          уничтожение.
        </div>
      </div>

      <h2>1. Общие положения</h2>
      <br />
      1.1. Администратор разработал Политику в соответствии с Конституцией РФ,
      Федеральным законом от 27.07.2006 № 152-ФЗ “О персональных данных”,
      другими нормативными правовыми актами, определяющими порядок работы с
      Персональными данными и требованиями к обеспечению их безопасности.
      <br />
      1.2. Назначением Политики является: <br />
      (1) обеспечение защиты прав и свобод человека и гражданина при обработке
      его Персональных данных, в том числе защиты прав на неприкосновенность
      частной жизни, личную и семейную тайну; <br />
      (2) четкое и неукоснительное соблюдение требований законодательства РФ в
      области персональных данных; <br />
      (3) исключение несанкционированных действий любых третьих лиц по
      Обработке; <br />
      (4) предотвращение возникновения возможной угрозы безопасности
      Пользователей. <br />
      1.3. Пользователь выражает свое согласие с условиями Политики при
      направлении своих Персональных данных Администратору, в том числе в момент
      регистрации в Системе, заполнения формы заказа на странице Системы. <br />
      1.4. Политика действует в отношении всех Персональных данных, которые
      Администратор может получить от Пользователей. <br />
      1.5. Администратор вправе вносить изменения в Политику. Новая редакция
      Политики вступает в силу с момента ее публикации. Если Пользователь
      продолжит использовать Систему после вступления в силу новой редакции
      Политики, это будет означать, что он согласился с условиями новой
      редакции. <br />
      <br />
      <h2>2. Цели Обработки</h2>
      <br />
      Администратор обрабатывает Персональные данные в целях: <br />
      2.1. идентификации: регистрация и идентификация Пользователя в том числе
      при получении доступа к личному кабинету в Системе; <br />
      2.2. исполнения обязательств: заключение и исполнение договоров в Системе;
      <br />
      2.3. поддержание связи: участие Пользователей в акциях, конкурсах и
      программах лояльности Администратора, установление и поддержание связи с
      Пользователем, направление уведомлений, рекламы, ответов на запросы
      Пользователя, информирование Пользователя о работе <br />
      Системы, прочих новостях Администратора, уточнение деталей заказа;
      <br />
      2.4. обеспечение работоспособности: обеспечение работоспособности и
      безопасности Системы (предотвращение случаев мошенничества и других
      злоупотреблений, а также для расследование таких случаев). <br />
      <br />
      <h2>3. Принципы Обработки</h2>
      <br />
      Администратор обрабатывает Персональные данные на основе принципов:
      <br />
      3.1. законная и справедливая основа Обработки; <br />
      3.2. обработка в соответствии с конкретными, заранее определенными и
      законными целями; <br />
      3.3. недопущение объединения баз данных, содержащих Персональные данные,
      Обработка которых осуществляется в целях, несовместимых между собой;
      <br />
      3.4. соответствие содержания и объема Персональных данных заявленным целям
      Обработки; <br />
      3.5. точность, достаточность, актуальность и достоверность Персональных
      данных; <br />
      3.6. законность технических мер, направленных на Обработку; <br />
      3.7. разумность и целесообразность Обработки; <br />
      3.8. хранение Персональных данных не дольше, чем этого требуют цели
      Обработки, если срок хранения не установлен Законом, договором, стороной
      которого является Пользователь; <br />
      3.9. уничтожение или обезличивание Персональных данных по достижении целей
      Обработки или в случае утраты необходимости в их достижении, если иное не
      предусмотрено Законом. <br />
      <br />
      <h2>4. Перечень обрабатываемых Персональных данных</h2>
      <br />
      4.1. Администратор обрабатывает общие Персональные данные. Специальные и
      биометрические персональные данные Администратор не обрабатывает. <br />
      4.2. Для целей идентификации Администратор использует следующие
      Персональные данные: имя, адрес электронной почты, номер телефона.
      <br />
      4.3. Для целей исполнения обязательств и поддержания связи Администратор
      вправе использовать следующие Персональные данные: фамилия, имя, отчество,
      номер телефона, адрес электронной почты, дата и место рождения; данные и
      копию документа, удостоверяющего личность; адрес регистрации по месту
      жительства; адрес фактического проживания; документ об образовании, СНИЛС,
      гражданство. <br />
      4.4. Для целей обеспечения работоспособности Администратор использует иные
      Персональные данные: <br />
      (1) данные о технических средствах (устройствах): IP-адрес, вид
      операционной системы, тип браузера, географическое положение, поставщик
      услуг сети Интернет. <br />
      (2) сведения о поведении Пользователя в Системе (в том числе дата, время и
      количество посещений, сведения о посещенных страницах, о переходе с других
      ресурсов, о направленных заявках). <br />
      (3) информация, автоматически получаемая при доступе к Системе, в том
      числе с использованием файлов cookies. Файлы cookies представляют собой
      фрагменты текста, который автоматически сохраняется в память
      интернет-браузера Пользователя. Это позволяет Системе в необходимых
      случаях обращаться к сохраненной информации на компьютере Пользователя и
      извлекать ее. Пользователь вправе изменить настройки своего
      интернет-браузера и отказаться от сохранения файлов cookies. <br />
      4.5. Администратор не проверяет: <br />
      (1) дееспособность Пользователя; <br />
      (2) достоверность предоставленных Пользователем Персональных данных.
      <br />
      4.6. Пользователь гарантирует: <br />
      (1) предоставленные Персональные данные являются достоверными, актуальными
      и не нарушают законодательство РФ; <br />
      (2) получение согласия третьего лица на передачу Персональных данных
      Администратору, последующую Обработку, если Персональные данные относятся
      к третьему лицу. <br />
      <br />
      <h2>5. Обработка Персональных данных</h2>
      <br />
      5.1. Обработка включает: сбор, запись, систематизацию, накопление,
      хранение, уточнение (обновление, изменение), извлечение, использование,
      передачу (распространение, предоставление, доступ), обезличивание,
      блокирование, удаление, уничтожение. <br />
      5.2. Субъектами Персональных данных являются Пользователи, которые указали
      данные при регистрации в Системе, в форме заказа на странице Системы.
      <br />
      5.3. Администратор собирает Персональные данные следующими способами:
      <br />
      (1) Пользователь предоставляет данные при заполнении форм Системы;
      <br />
      (2) с помощью средств коммуникации, в том числе по телефону, по
      электронной почте, в личном кабинете и др.; <br />
      (3) автоматический сбор данных о Пользователе с помощью технологий,
      веб-протоколов, файлов “cookie”, веб-отметок, которые запускаются только
      при вводе Пользователем своих данных. <br />
      5.4. Администратор хранит Персональные данные: <br />
      (1) исключительно на должным образом защищенных электронных носителях;
      <br />
      (2) в Системе с применением автоматизированной обработки, за исключением
      случаев, когда неавтоматизированная Обработка необходима в связи с
      исполнением требований законодательства РФ; <br />
      (3) с использованием баз данных, находящихся на территории РФ; <br />
      (4) для всех целей: с момента дачи согласия и до момента отзыва согласия
      или до тех пор, пока этого требуют цели Обработки. <br />
      5.5. Администратор не распространяет и не предоставляет Персональные
      данные третьим лицам без письменного согласия Пользователя, за исключением
      следующих случаев: <br />
      (1) передача партнерам (оказывающим следующие услуги: курьерские, почтовой
      связи, обработка информации, формирование статистических данных, для
      стандартизации (уточнение и обновление тестовых норм), анализ данных
      исполнение заказов потребителей, предоставление кредитов, иные виды
      обслуживания потребителей, определение интереса Пользователей к продуктам
      и услугам Администратора, проведение опросов, направленных на изучение
      Пользователей или удовлетворения качеством сервиса и т.п.) в целях
      исполнения договоров с Пользователями либо с лицом, в интересах которого
      действует Пользователь; <br />
      (2) передача в отдельные, самостоятельно функционирующие, но входящие в
      состав Системы, приложения и базы данных; <br />
      (3) в целях предупреждения, пресечения незаконных действий Пользователя и
      защиты законных интересов Администратора и третьих лиц, а также в случаях,
      установленных законодательством РФ; <br />
      (4) по мотивированному запросу судебных органов, органов государственной
      безопасности, прокуратуры, полиции, в следственные органы, в иные органы и
      организации в случаях, установленных нормативными правовыми актами,
      обязательными для исполнения. <br />
      5.6. Администратор вправе предоставить Персональные данные лицам,
      выполняющим техническую поддержку Системы, для осуществления Обработки по
      поручению Администратора в целях выполнения договорных обязательств.
      Полный перечень таких лиц Администратор предоставляет Пользователю по
      запросу. <br />
      5.7. Администратор предупреждает получающих лиц о том, что передаваемые
      данные могут быть использованы лишь в целях, для которых они сообщены.
      <br />
      5.8. В случае подтверждения факта неточности Персональных данных
      Администратор актуализирует их. <br />
      5.9. Администратор уничтожает Персональные данные в следующих случаях:
      <br />
      (1) наличие угрозы безопасности Системы; (2) истечение срока хранения
      Персональных данных; <br />
      (3) по запросу Пользователя. <br />
      5.10. Администратор удаляет всю полученную от Пользователя информацию при
      отзыве им согласия. Право использования Системы прекращается. <br />
      5.11. Администратор уничтожает Персональные данные путем стирания или
      форматирования носителя. <br />
      5.12. Администратор вправе временно прекратить обработку Персональных
      данных, т.е. блокировать Обработку, за исключением случаев, когда
      Обработка необходима для уточнения Персональных данных. <br />
      <br />
      <h2>6. Права Пользователя</h2>
      <br />
      Пользователь вправе направить Администратору запрос в порядке,
      предусмотренном Политикой, на получение информации об Обработке, в том
      числе содержащий: <br />
      6.1. подтверждение факта Обработки; <br />
      6.2. правовые основания Обработки; <br />
      6.3. цели и применяемые Администратором способы Обработки; <br />
      6.4. какие именно Персональные данные обрабатываются и источник их
      получения; <br />
      6.5. сроки Обработки, в том числе сроки хранения Персональных данных;
      <br />
      6.6. порядок осуществления прав, предусмотренных законодательством РФ;
      <br />
      6.7. информацию об осуществленной или о предполагаемой <br />
      трансграничной передаче данных; <br />
      6.8. сведения о лицах, которым могут быть раскрыты Персональные данные на
      основании договора с Администратором или в соответствии с
      законодательством РФ; <br />
      6.9. наименование или фамилию, имя, отчество и адрес лица, осуществляющего
      Обработку по поручению Администратора, если <br />
      Обработка поручена или будет поручена такому лицу; <br />
      6.10. иные сведения, предусмотренныезаконодательством РФ.<br />
      <br />
      <h2>7. Обязанности Администратора</h2>
      <br />
      Администратор обязан: <br />
      7.1. по запросу Пользователя предоставлять информацию об Обработке или
      обоснованный отказ; <br />
      7.2. принимать меры, необходимые и достаточные для обеспечения выполнения
      обязанностей, предусмотренных законодательством РФ. <br />
      7.3. по требованию Пользователя уточнять обрабатываемые Персональные
      данные, блокировать или удалять, если они являются неполными, устаревшими,
      неточными, незаконно полученными или ненужными для заявленной цели
      Обработки; <br />
      7.4. обеспечить правомерность Обработки. Если обеспечить правомерность
      Обработки невозможно, Администратор в срок, не превышающий 10 рабочих дней
      с даты выявления неправомерной Обработки, обязан Персональные данные
      уничтожить или обеспечить их уничтожение; <br />
      7.5. прекратить Обработку и уничтожить Персональные данные (если их
      сохранение не требуется для целей Обработки) в срок, не превышающий 30
      дней с даты поступления от Пользователя отзыва согласия, за исключением
      случаев, когда Обработка может быть продолжена в соответствии с
      законодательством РФ. <br />
      <br />
      <h2>8. Меры по обеспечению защиты Персональных данных</h2>
      <br />
      8.1. Администратор обеспечивает реализацию правовых, организационных и
      технических мер, необходимых и достаточных для обеспечения защиты
      Персональных данных: <br />
      <br />
      <div class="terms">
        <div>Название группы мер</div>
        <div>Содержание группы меры</div>
        <div>Правовые меры</div>
        <div>
          - разработка Администратором локальных документов, реализующих
          требования законодательства РФ, в том числе – Политики и размещение ее
          в Системе; <br />
          - отказ от любых способов Обработки, не соответствующих целям, заранее
          предопределенным Администратором. <br />
        </div>
        <div>Организационные меры</div>
        <div>
          - назначение лица, ответственного за организацию Обработки;<br />
          - ограничение состава работников Администратора, имеющих доступ к
          Персональным данным, и организацию разрешительной системы доступа к
          ним; <br />- инструктаж и ознакомление работников Администратора,
          осуществляющих Обработку, с положениями <br />
          законодательства РФ о персональных данных, в том числе с требованиями
          к защите персональных данных, с локальными актами Администратора,
          регламентирующими порядок работы и защиты персональных данных; <br />
          - периодическая оценка рисков, касающихся процесса Обработки; <br />
          - проведение регулярных проверок в целях осуществления внутреннего
          контроля соответствия Обработки требованиям законодательства РФ.
          <br />
        </div>
        <div>Технические меры</div>
        <div>
          - предотвращение, в том числе путем проведение внутренних
          расследований, несанкционированного доступа к системам, в которых
          хранятся Персональные данные;<br />
          - резервирование и восстановление Персональных данных,
          работоспособности технических средств и программного обеспечения,
          средств защиты информации в информационных системах Персональных
          данных; <br />
          - иные необходимые меры безопасности. <br />
        </div>
      </div>

      <h2>9. Ограничение действия Политики</h2>
      <br />
      9.1. Действие Политики распространяется исключительно на Систему и не
      применяется к другим интернет-ресурсам. <br />
      9.2. Администратор не несет ответственности за действия третьих лиц,
      получивших доступ к Персональным данным Пользователя по его вине. <br />
      <br />
      <h2>10. Обращения Субъекта персональных данных</h2>
      <br />
      10.1. Пользователь или его представитель вправе направить Администратору
      свои обращения/запросы, в том числе относительно Обработки, отозвать
      согласие на Обработку в форме электронного документа по адресу электронной
      почты: connect@eilab.ru. <br />
      10.2. Запрос должен содержать следующую информацию: <br />
      (1) сведения о документе, удостоверяющего личность Пользователя; <br />
      (2) сведения, подтверждающие участие Пользователя вотношениях с
      Администратором (номер телефона, адрес электронной почты); <br />
      (3) данные представителя и подтверждение его полномочий (при обращении
      представителя). <br />
      (4) подпись Пользователя (представителя). <br />
      10.3. Администратор обязуется рассмотреть и направить ответ на поступивший
      запрос в течение 10 рабочих дней с момента поступления обращения. <br />
      10.4. Вся корреспонденция, полученная Администратором (обращения в
      письменной или электронной форме), относится к информации ограниченного
      доступа и не разглашается без письменного согласия Пользователя. <br />
      <br />
      <h2>11. Распространение персональных данных</h2>
      <br />
      11.1. Администратор с согласия Субъекта распространяет его данные
      в порядке и способами, указанными в согласии. <br />
      11.2. Информация об условиях и запретах Субъектов на распространение их
      персональных данных размещена на странице <router-link to="/conditions-distribution">
      https://way2wei.ru/conditions-distribution</router-link>. <br />
      <br />
      Реквизиты: <br />
      ООО “Лаборатория ЭИ” <br />
      Юридический адрес: 121205, г. Москва, вн. тер. г. муниципальный округ
      Можайский, тер. Сколково инновационного центра, б-р Большой, д. 42, стр.
      1, эт. 4, помещ. 88. <br />
      ИНН: 7703438337 <br />
      ОГРН: 5177746280168 <br />
      Email: connect@eilab.ru <br />
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
export default {
  name: "privacy-policy",
  setup() {
    useHead({
      title: "Политика конфиденциальности",
    });
  },
};
</script>

<style lang="scss" scoped>
.privacy-policy-wrapper {
  width: 100%;
  padding: 121px 0 50px;
  font-size: 17px;
  line-height: 24px;
  color: #414352;

  .terms {
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 25px 0;
    margin-top: 35px;
  }

  .signature {
    margin-top: 25px;
  }

  .notation {
    text-align: end;
    font-style: italic;
  }

  .container {
    position: relative;
    margin: 0 auto;
    padding: 0;
    max-width: 1170px;
  }

  .head-text {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 28px;
    line-height: 1.2;
    margin: 30px 0 15px;
  }

  p {
    margin: 0 0 15px;
  }

  ul,
  ol {
    margin: 0 0 15px;
    padding: 0 0 0 40px;
  }

  @media (max-width: 1189px) {
    padding: 140px 20px 30px;

    .container {
      padding: 0 15px;
    }

    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
  }

  @media (max-width: 764px) {
    padding: 80px 0 30px;

    .terms {
      grid-template-columns: 100%;
    }
  }
}
</style>